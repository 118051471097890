import React from "react";
import { graphql, StaticQuery } from "gatsby";
import Header from "./header";
import { Helmet } from "react-helmet";

export default function Layout({ children }) {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
              menuLinks {
                name
                link
              }
            }
          }
        }
      `}
      render={(data) => (
        <React.Fragment>
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[
              { name: "description", content: "Coop Pizza" },
              { name: "keywords", content: "Coop Pizza, pizza" },
            ]}
          ></Helmet>
          <Header menuLinks={data.site.siteMetadata.menuLinks} />
          {children}
        </React.Fragment>
      )}
    />
  );
}