import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "gatsby";
import * as React from "react";

const Header = ({ menuLinks }) => {
  /*
    const [classNames, setClassNames] = React.useState("is-top")

    React.useEffect(() => {
        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    });

    const isSticky = (e) => {
        const scrollTop = window.scrollY;
        scrollTop >= 5 ? setClassNames("is-sticky") : setClassNames("is-top");
    };
*/

  const [isNavOpen, setIsNavOpen] = React.useState(false); // initiate isNavOpen state with false

  return (
    <div className="header-section w-full h-20 pt-3 ml-30">
      <div className="float-left">
        <img src="/logo.svg" className="h-12" />
      </div>
      {/* <nav className="static block float-right">
        <section className="flex sm:hidden">
          <div
            className="mr-8 mt-3"
            onClick={() => setIsNavOpen((prev) => !prev)}
          >
            <FontAwesomeIcon
              icon={faBars}
              className="text-white h-6 align-middle"
            />
          </div>

          <div
            className={
              "mt-20 bg-zinc-900 " + (isNavOpen ? "showMenuNav" : "hideMenuNav")
            }
          >
            <ul className="flex flex-col items-center justify-between w-full">
              {menuLinks.map((link) => (
                <li
                  key={link.name}
                  className="border-t py-4 p-0 border-zinc-700 w-full text-center hover:bg-zinc-800 text-grey hover:text-red-400"
                >
                  <Link to={link.link}>{link.name}</Link>
                </li>
              ))}
            </ul>
          </div>
        </section>
        <ul className="align-middle hidden sm:flex">
          {menuLinks.map((link) => (
            <li
              key={link.name}
              style={{
                listStyleType: `none`,
                padding: `1rem`,
              }}
              className="text-dark-grey hover:text-blue"
            >
              <Link to={link.link}>{link.name}</Link>
            </li>
          ))}
        </ul>
      </nav> */}
    </div>
  );
};

export default Header;
