import * as React from "react";

interface LandingProps {
  imgUrl: string;
}

const Landing = ({ imgUrl }: LandingProps) => {
  React.useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.fbgcdn.com/embedder/js/ewm2.js';
    script.defer = true;
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <section
      className="min-w-screen min-h-screen p-0 m-0 flex bg-blend-multiply bg-[#242427]/50 bg-cover"
      style={{
        backgroundImage: "url('/" + imgUrl + "')"
      }}
    >
      <div className="m-auto">
      <h2 className="text-5xl text-center text-white font-bold mb-8">Pitsad</h2>
        <table className="rounded-lg overflow-hidden table-fixed w-96 bg-white m-auto">
          <thead>
            <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
              <th className="py-3 px-6 text-left" style={{ width: "65%" }}>Hiiumaa</th>
              <th className="py-3 px-6 text-left" style={{ width: "35%" }}></th>
            </tr>
          </thead>
          <tbody className="text-gray-600 text-sm font-light">
            <tr className="border-b border-gray-200 hover:bg-gray-100">
              <td className="StoreFront py-3 px-6 text-left">
                <div>Hiiumaa</div>
                <div className="text-xs text-gray-600 font-normal">Heltermaa mnt 16, Kärdla, 92414</div>
              </td>
              <td className="py-3 px-6 text-right">
                <button className="OrderButton" data-glf-cuid="fc7c401a-30b6-4126-b5b4-52653b01e6d4" data-glf-ruid="73ddf3fb-0b1e-4af8-a00a-142043e4d6bf">
                Tellin
                </button>
              </td>
            </tr>
            <tr className="border-b border-gray-200 hover:bg-gray-100">
              <td className="StoreFront py-3 px-6 text-left">
                <div>Emmaste</div>
                <div className="text-xs text-gray-600 font-normal">Emmaste, 92001</div>
              </td>
              <td className="py-3 px-6 text-right">
                <button className="OrderButton" data-glf-cuid="fc7c401a-30b6-4126-b5b4-52653b01e6d4" data-glf-ruid="089bde57-bffa-4dfe-9610-5ba59fa275b4">
                Tellin
                </button>
              </td>
            </tr>
          </tbody>
          <thead>
            <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
              <th className="py-3 px-6 text-left" style={{ width: "65%" }}>Saaremaa</th>
              <th className="py-3 px-6 text-left" style={{ width: "35%" }}></th>
            </tr>
          </thead>
          <tbody className="text-gray-600 text-sm font-light">
            <tr className="border-b border-gray-200 hover:bg-gray-100">
              <td className="StoreFront py-3 px-6 text-left">
                <div>Kummeli Konsum</div>
                <div className="text-xs text-gray-600 font-normal">Kummeli põik 1, Kudjape, 93851</div>
              </td>
              <td className="py-3 px-6 text-right">
                <button className="OrderButton" data-glf-cuid="fc7c401a-30b6-4126-b5b4-52653b01e6d4" data-glf-ruid="73ddf3fb-0b1e-4af8-a00a-142043e4d6bf">
                Tellin
                </button>
              </td>
            </tr>
            <tr className="border-b border-gray-200 hover:bg-gray-100">
              <td className="StoreFront py-3 px-6 text-left">
                <div>Ranna kauplus</div>
                <div className="text-xs text-gray-600 font-normal">Abaja 12, Kuressaare, 93813</div>
              </td>
              <td className="py-3 px-6 text-right">
                <button className="OrderButton" data-glf-cuid="fc7c401a-30b6-4126-b5b4-52653b01e6d4" data-glf-ruid="089bde57-bffa-4dfe-9610-5ba59fa275b4">
                Tellin
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default Landing;
