import * as React from "react";

import Landing from "../components/landing";
import Layout from "../components/layout";
import Footer from "../components/footer";

const IndexPage = () => {
  return (
    <Layout>
      <Landing imgUrl="landing_pizza.jpg" />
      <Footer />
    </Layout>
  );
};

export default IndexPage;