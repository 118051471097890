import * as React from "react";

const Footer = () => {
  return (
    <div className="w-full mt-6 p-6 bg-white">
      <p className="mt-8 text-center text-xs text-dark-grey">
        Copyright 2023 Semaforce OÜ
      </p>
    </div>
  );
};

export default Footer;
